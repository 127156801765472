@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

@font-face {
  font-family: 'Montserrat-Bold';
  src: local('Montserrat-Bold'), url(./fonts/Montserrat-Bold.ttf) format('opentype');
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: local('Montserrat-Medium'), url(./fonts/Montserrat-Medium.ttf) format('opentype');
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'), url(./fonts/Montserrat-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: local('Montserrat-SemiBold'), url(./fonts/Montserrat-SemiBold.ttf) format('opentype');
}

@font-face {
  font-family: 'OpenSans-Regular';
  src: local('OpenSans-Regular'), url(./fonts/OpenSans-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'Raleway-Regular';
  src: local('Raleway-Regular'), url(./fonts/Raleway-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'OpenSans_Condensed-Regular';
  src: local('OpenSans_Condensed-Regular'), url(./fonts/OpenSans_Condensed-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'OpenSans_SemiCondensed-Regular';
  src: local('OpenSans_SemiCondensed-Regular'), url(./fonts/OpenSans_SemiCondensed-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'RobotoMono-Regular';
  src: local('RobotoMono-Regular'), url(./fonts/RobotoMono-Regular.ttf) format('opentype');
}

body {
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  min-height: 100% !important;
  height: 100%;
}

img {
  object-fit: cover;
  border: none;
}

button {
  border: none;
  cursor: pointer;
}

html {
  min-height: 100% !important;
  min-height: 100%;
  margin: 0;
  padding: 0;
}

p,
h1,
h2,
h3,
ul {
  margin: 0;
  padding: 0;
}